import './styles/App.css';
import Logo from './ND_logo 1.svg'

export default function App() {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateInYourTimeZone = new Date('2024-10-16T12:30:00.000Z');

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const dateFormatter = new Intl.DateTimeFormat('en-US', { ...dateFormatOptions, timeZone: userTimeZone });
  const datePart = dateFormatter.format(dateInYourTimeZone).replace(',', '');
  const timeZoneOffset = ` (UTC${userTimeZone.startsWith('-') ? '' : '+'}${new Date().getTimezoneOffset() / -60})`;
  const formattedDate = `${datePart}${timeZoneOffset}`;



  return (
    <div className="App">
      <header>
        <div className='headline'></div>
        {/* <img src="/Logo.png" alt="logo" /> */}
        <img src={Logo} alt="logo" />
      </header>
      <main>
        <section className='title'>
          <p>We Are Upgrading Our System <br />システムをアップグレードしています <br />系統升級中</p>
        </section>
        <section className="content">
          <div className="info">
            <p>To provide better service, we are upgrading our system and will pause our service for a while. We apologize for any inconvenience caused.</p>
            <p>より良いサービスを提供するために、現在サービスを一時停止してシステムをアップグレードしています。ご不便をおかけしており、申し訳ございません。</p>
            <p>為提供更好的服務，我們正在升級我們的系統，將暫停一段時間的服務。對於造成的任何不便，我們深感抱歉。</p>
          </div>
          <div className="period">
            <p>Our service is estimated to be back by: <br />サービスの再開予定時間は次の通りです。<br />我們的服務預計將在下列時間再度上線：</p>
            <span>{formattedDate}</span>
         </div>
         <div className="contact">
          <p>Please contact us if you have any question.
             <br />ご質問がございましたら、お気軽にお問い合わせください。 <br />
             如有任何問題請洽  <br />
            <a href="mailto: service@nextdrive.io">service@nextdrive.io</a>
          </p>
        </div>
        </section>
      </main>
      <footer>
        <p>NextDrive</p>
        <span>©2023 NextDrive Co. All rights reserved.</span>
      </footer>
    </div>
  );
}

 
